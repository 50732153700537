//
//button.scss
//

@each $color,
$value in $theme-colors {

    .btn-soft-#{$color} {
        background-color: rgba($value, 0.15) !important;
        border-color: transparent;
        color: #{$value} !important;

        &:hover,
        &:focus,
        &:active,
        &.active,
        &.focus,
        &:not(:disabled):not(.disabled):active,
        &:not(:disabled):not(.disabled):active:focus,
        .open>.dropdown-toggle.btn-primary {
            color: $white !important;
            background: rgba($value, 1) !important;
        }
    }
}

.btn-hover {
    transition: all 0.5s ease;
    &:hover {
        transform: translateY(-6px);
    }
}

.btn-outline-primary:hover {
    color: $white !important;
}

.btn {
    &.btn-info,
    &.btn-purple,
    &.btn-warning {
        color: $white;
    }
}