//
//general.scss
//

/*****************dark-mode******************/

body[data-layout-mode='dark'] {
      background-color: $body-dark-bg;
      color: $gray-dark-800;

      .bg-light {
            background-color: $gray-dark-300 !important;
      }

      .text-dark {
            color: $gray-100 !important;
      }

      .text-muted {
            color: $gray-500 !important;
      }
}