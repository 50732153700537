//
//navs=tabs.scss
//

.nav-tabs {
    .nav-item {
        .nav-link {
            color: $dark;
            transition: all 0.5s ease;
        }
    }
}

.nav-pills {
    .nav-item {
        .nav-link {
            color: $dark;

            &.active {
                color: $gray-100;
                background-color: $primary;
            }
        }
    }
}

/*****************dark-mode******************/

body[data-layout-mode="dark"] {
    .nav {
        .nav-item {
            .nav-link {
                color: $gray-100;
            }
        }
    }

    .nav-tabs {
        border-color: $gray-dark-300;

        .nav-item {
            .nav-link {
                color: $gray-100;

                &.active,
                &:hover {
                    background-color: $gray-dark-200;
                    border-color: $gray-dark-300 $gray-dark-300 transparent;
                }
            }

            &.show {
                .nav-link {
                    background-color: $gray-dark-100;
                }
            }
        }
    }
}